import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { NPSAverageRequest, NPSResponse, NPSResponseCounts } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  npsResponseListView(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<NPSResponse>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/nps?${query}`
    return httpService.get(url)
  },
  npsAverage(params: NPSAverageRequest): Promise<AxiosResponse<NPSResponseCounts>> {
    const {
      fromDate,
      toDate,
      marketId,
      operatorId,
      npsTypeId,
    } = params

    let query = `fromDate=${fromDate}&toDate=${toDate}`
    if (marketId) {
      query += `&marketId=${marketId}`
    }
    if (operatorId) {
      query += `&operatorId=${operatorId}`
    }
    if (npsTypeId) {
      query += `&npsTypeId=${npsTypeId}`
    }

    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/npsResponse/average?${query}`
    return httpService.get(url)
  },
  npsByMarket(params: NPSAverageRequest): Promise<AxiosResponse<NPSResponseCounts>> {
    const { fromDate, toDate, npsTypeId } = params

    let query = `fromDate=${fromDate}&toDate=${toDate}`
    if (npsTypeId) {
      query += `&npsTypeId=${npsTypeId}`
    }

    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/npsResponse/byMarket?${query}`
    return httpService.get(url)
  },
  exportNPSResponses(params: TableViewParameters): Promise<AxiosResponse<any>> {
    const { sorts, filters } = params

    let query = ''
    if (sorts) {
      query += query.length ? `&${sorts}` : `${sorts}`
    }
    if (filters) {
      query += query.length ? `&${filters}` : `${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/tables/nps/export?${query}`
    return httpService.get(url, { responseType: 'blob' })
  },
}
