<template>
  <Sheet>
    <div style="position: relative; margin-left: 7px">
      <h1 style="display: inline-block; font-size: 24px">Metrics Map</h1>
      <v-layout row>
        <v-flex shrink xs3>
          <MarketMetricsList
            :loading="loading"
            :market-metrics="marketRegions"
            @change-metric="(e) => changeMetric(e)"
          />
        </v-flex>
        <v-flex grow xs9>
          <VoronoiMetricsMap
            v-if="marketRegions.length > 0"
            id="voronoiMetricsMap"
            ref="voronoiMetricsMap"
            :market-metrics="marketRegions"
          />
        </v-flex>
      </v-layout>
    </div>
  </Sheet>
</template>

<script>
import Sheet from '@/layouts/Sheet.vue'
import Voronoi from 'voronoi'
import MarketMetricsList from '@/components/MarketMetricsList.vue'
import VoronoiMetricsMap from '@/components/VoronoiMetricsMap.vue'
import markets from '@/services/markets'
import nps from '@/services/nps'

export default {
  components: {
    Sheet,
    MarketMetricsList,
    VoronoiMetricsMap,
  },
  data() {
    return {
      loading: false,
      markets: [],
      marketRegions: [],
    }
  },
  async mounted() {
    this.loading = true
    await this.getMarkets()

    const voronoi = new Voronoi()
    const boundingBox = {
      xl: -126.2844079,
      xr: -65.4513812,
      yt: 23.2433195,
      yb: 50.8457868,
    }

    const sites = this.markets.map((market) => {
      return {
        x: market.address.lng,
        y: market.address.lat,
        market: market,
      }
    })

    const diagram = await voronoi.compute(sites, boundingBox)

    for (const cell of diagram.cells) {
      if (cell.halfedges.length == 0) {
        continue
      }

      let path = []
      for (const edge of cell.halfedges) {
        const coordinate = edge.getStartpoint()
        path.push({
          lng: coordinate.x,
          lat: coordinate.y,
        })
      }
      this.marketRegions.push({
        market: cell.site.market,
        polygon: path,
        value: 0,
        displayValues: {},
      })
    }

    this.marketRegions = this.marketRegions.sort((a, b) => {
      return a.market.marketName.localeCompare(b.market.marketName)
    })

    this.loading = false
  },
  methods: {
    async getMarkets() {
      this.markets = await markets
        .tableView({
          pageSize: -1,
        })
        .then((data) => data.data.resultList)
    },
    async getMarketPostBookingNPS(fromDate, toDate) {
      const params = {
        fromDate: fromDate + ' 24:00:00',
        toDate: toDate + ' 24:00:00',
        npsTypeId: 1,
      }
      const retrieve = await nps
        .npsByMarket(params)
        .then((d) => d.data?.npsResponsesByMarket)

      return retrieve
    },
    async getMarketPostTripNPS(fromDate, toDate) {
      const params = {
        fromDate: fromDate + ' 24:00:00',
        toDate: toDate + ' 24:00:00',
        npsTypeId: 2,
      }
      const retrieve = await nps
        .npsByMarket(params)
        .then((d) => d.data?.npsResponsesByMarket)

      return retrieve
    },
    async changeMetric({ metricName, fromDate, toDate } = event) {
      this.marketRegions = this.marketRegions.map((marketRegion) => {
        return {
          ...marketRegion,
          value: 0,
          displayValues: [],
        }
      })

      if (metricName === 'post-booking-nps') {
        const data = await this.getMarketPostBookingNPS(fromDate, toDate)
        this.marketRegions = this.marketRegions.map((marketRegion) => {
          let marketData = data.find(
            (obj) => obj.marketId === marketRegion.market.marketId
          )
          return {
            ...marketRegion,
            value:
              marketData?.promoterCount / marketData?.responseCount -
                marketData?.detractorCount / marketData?.responseCount || 0,
            displayValues: this.getNPSDisplayValues(marketData),
          }
        })
      } else if (metricName === 'post-trip-nps') {
        const data = await this.getMarketPostTripNPS(fromDate, toDate)
        this.marketRegions = this.marketRegions.map((marketRegion) => {
          let marketData = data.find(
            (obj) => obj.marketId === marketRegion.market.marketId
          )
          return {
            ...marketRegion,
            value:
              marketData?.promoterCount / marketData?.responseCount -
                marketData?.detractorCount / marketData?.responseCount || 0,
            displayValues: this.getNPSDisplayValues(marketData),
          }
        })
      }
    },
    getNPSDisplayValues(marketData) {
      return [
        {
          label: 'NPS Score',
          value: `${
            marketData?.responseCount
              ? 100 *
                (
                  marketData?.promoterCount / marketData?.responseCount -
                  marketData?.detractorCount / marketData?.responseCount
                ).toPrecision(2)
              : 'N/A'
          }`,
        },
        {
          label: 'Promoters',
          value: `${
            marketData?.responseCount
              ? 100 *
                  (
                    marketData?.promoterCount / marketData?.responseCount
                  ).toPrecision(2) +
                '%'
              : 'N/A'
          }`,
        },
        {
          label: 'Passives',
          value: `${
            marketData?.responseCount
              ? 100 *
                  (
                    marketData?.passiveCount / marketData?.responseCount
                  ).toPrecision(2) +
                '%'
              : 'N/A'
          }`,
        },
        {
          label: 'Detractors',
          value: `${
            marketData?.responseCount
              ? 100 *
                  (
                    marketData?.detractorCount / marketData?.responseCount
                  ).toPrecision(2) +
                '%'
              : 'N/A'
          }`,
        },
        {
          label: 'Responses',
          value: marketData?.responseCount || 'N/A',
        },
      ]
    },
    getSquaredDistance([x1, y1], [x2, y2]) {
      return (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1)
    },
    checkPointOutsideOfBox(box, point) {
      return (
        point.x < box.xl ||
        point.x > box.xr ||
        point.y < box.yb ||
        point.y > box.yt
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-section {
  border: 1px solid $gray-light;
  border-radius: 5px;
}

.center-divider {
  border-left: 1px solid $gray-light;
}
</style>
