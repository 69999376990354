<template>
  <GmapMap
    ref="gMap"
    class="map"
    :zoom="mapConfig.zoom"
    :center="mapConfig.center"
    :options="mapConfig.options"
  >
    <VoronoiCell
      v-for="(marketMetric, marketMetricIndex) in marketMetrics"
      :key="marketMetricIndex"
      :polygon="marketMetric.polygon"
      :value="marketMetric.value"
      :highlight="marketMetric.market.marketId === hoverMarket"
    />
    <VoronoiCell
      v-if="highlightedMarketMetric"
      key="highlighted-market"
      :polygon="highlightedMarketMetric.polygon"
      :value="highlightedMarketMetric.value"
      :highlight="true"
    />
  </GmapMap>
</template>

<script>
import VoronoiCell from './VoronoiCell.vue'
import { mapStyles } from './mapStyles.js'
import { gmapApi } from 'vue2-google-maps'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
    VoronoiCell,
  },
  props: {
    marketMetrics: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      map: null,
      hoverMarket: null,
      mapConfig: {
        center: { lat: 37.0902, lng: -95.7129 },
        zoom: 4.2,
        options: {
          clickableIcons: false,
          streetViewControl: false,
          fullScreenControl: true,
          mapTypeControl: false,
          styles: mapStyles,
          fullscreenControlOptions: {
            position: -1,
          },
          gestureHandling: 'none',
          minZoom: 4.2,
          maxZoom: 4.2,
        },
      },
    }
  },
  computed: {
    google: gmapApi,
    highlightedMarketMetric() {
      return this.marketMetrics.find(
        (marketMetric) => marketMetric.market.marketId === this.hoverMarket
      )
    },
  },
  async mounted() {
    this.map = await this.$refs.gMap.$mapPromise
    EventBus.$on('hover-market', (hoverMarket) => {
      this.hoverMarket = hoverMarket
    })
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
  z-index: 0;
}
</style>
