<template>
  <gmap-polygon
    v-if="show"
    :paths="polygon"
    :options="polygonOptions"
  ></gmap-polygon>
</template>

<script>
export default {
  props: {
    polygon: {
      type: Object,
      required: true,
    },
    value: {
      type: Number,
      required: false,
      default: 0.2,
    },
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      show: true,
    }
  },
  computed: {
    polygonColor() {
      return this.$cr.theme.blue
    },
    polygonOptions() {
      return {
        fillColor: this.polygonColor,
        fillOpacity: 0.7 * this.value,
        strokeColor: this.highlight ? '#ffffff' : '#000000',
        strokeOpacity: this.highlight ? 1 : 0.4,
        strokeWeight: 2,
      }
    },
  },
}
</script>
