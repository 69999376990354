<template>
  <v-layout column>
    <v-flex
      justify-space-between
      pr-3
      class="d-flex"
      :style="
        $vuetify.breakpoint.xlOnly
          ? 'flex-direction: row;'
          : 'flex-direction: column;'
      "
    >
      <div>
        <CRInput
          id="from-date-picker"
          v-model="fromDate"
          label="From Date"
          type="date"
          name="from-date-picker"
          :clearable="true"
          autocomplete="off"
          clear-icon="close"
          @change="refresh"
        />
      </div>
      <div
        :style="
          $vuetify.breakpoint.xlOnly
            ? 'margin-left: 15px;'
            : 'margin-top: -15px;'
        "
      >
        <CRInput
          id="to-date-picker"
          v-model="toDate"
          label="To Date"
          type="date"
          name="to-date-picker"
          :clearable="true"
          autocomplete="off"
          clear-icon="close"
          @change="refresh"
        />
      </div>
    </v-flex>
    <v-flex justify-space-between align-center pr-3 style="margin-top: -15px">
      <label>Metric</label>
      <CRSelect
        id="metric-option-selector"
        v-model="metricSelection"
        :items="metricOptions"
        item-text="label"
        item-value="value"
        solo
        flat
        clearable
      />
    </v-flex>
    <v-layout justify-space-between align-center pb-2 pr-3>
      <h4 style="font-size: 20px; margin-right: 10px; margin-top: -20px">
        Markets
      </h4>
    </v-layout>
    <v-flex style="height: 600px; overflow-y: auto">
      <template v-if="loading">
        <v-flex v-for="i in [0, 1, 2]" :key="i" class="market-card">
          <SkeletonBox style="height: 200px" />
        </v-flex>
      </template>
      <template v-else>
        <v-flex
          v-for="(marketMetric, marketMetricIndex) in marketMetrics"
          :key="marketMetricIndex"
          class="market-card"
          @mouseover="hoverMarket = marketMetric.market.marketId"
          @mouseleave="hoverMarket = null"
        >
          <v-layout column>
            <v-row style="display: flex; align-items: center; margin: 5px">
              <span style="display: flex; align-items: center; cursor: pointer">
                <p style="margin-right: 10px">
                  {{ marketMetricIndex + 1 }}
                </p>
                <p style="font-weight: 600; font-size: 18px">
                  {{ marketMetric.market.marketName }}
                </p>
              </span>
            </v-row>
            <v-row style="display: flex; align-items: center; margin: 5px">
              <span
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: start;
                  cursor: pointer;
                "
              >
                <div
                  v-for="(displayValue,
                  displayValueIndex) in marketMetric.displayValues"
                  :key="displayValueIndex"
                >
                  <p>
                    <span style="font-weight: 600; margin-right: 5px">
                      {{ displayValue.label }}:
                    </span>
                    {{ displayValue.value }}
                  </p>
                </div>
              </span>
            </v-row>
          </v-layout>
        </v-flex>
      </template>
    </v-flex>
  </v-layout>
</template>

<script>
import SkeletonBox from '@/components/SkeletonBox.vue'
import { EventBus } from '@/utils/event-bus'
import { DateTime } from 'luxon'

export default {
  components: {
    SkeletonBox,
  },
  props: {
    marketMetrics: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      metricOptions: [
        {
          label: 'NPS Post Booking',
          value: 'post-booking-nps',
        },
        {
          label: 'NPS Post Trip',
          value: 'post-trip-nps',
        },
      ],
      metricSelection: '',
      hoverMarket: null,
      fromDate: DateTime.local().minus({ days: 30 }).toFormat('yyyy-MM-dd'),
      toDate: DateTime.local().toFormat('yyyy-MM-dd'),
    }
  },
  watch: {
    metricSelection() {
      this.updateMetric()
    },
    fromDate() {
      this.updateMetric()
    },
    toDate() {
      this.updateMetric()
    },
    hoverMarket() {
      EventBus.$emit('hover-market', this.hoverMarket)
    },
  },
  methods: {
    updateMetric() {
      this.$emit('change-metric', {
        metricName: this.metricSelection || 'none',
        fromDate: this.fromDate,
        toDate: this.toDate,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.market-card {
  border: 1px solid $input-border-gray;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 15px;
  padding: 10px;

  p {
    color: $black;
    margin: 0;
  }
}
</style>
