<template>
  <div class="gray-box" :class="`gray-box-${type}`" />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
  },
}
</script>

<style lang="scss" scoped>
// With some help from:
// https://markus.oberlehner.net/blog/skeleton-loading-animation-with-vue/
@import '~@/scss/charterup/variables';

.gray-box {
  background-color: $border-gray;
  border-radius: $border-radius-root;
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba($white, 0) 0,
      rgba($white, 0.2) 20%,
      rgba($white, 0.5) 60%,
      rgba($white, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.gray-box-text {
  height: 22px;
}

.gray-box-header {
  height: 26px;
}

.gray-box-button {
  height: 44px;
}
</style>
